import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormatDate } from "./Date"
export default ({ courtesy, event }) => {
  return courtesy.tickets.map((ticket, i) => {
    return (
      <Col lg="6" className="event" key={i}>
        <div className="ticket-card">
          <div className="ticket-header">
            {/* <Image
              imgUrl={event.headerImage}
              theme={{
                photoImg: { width: "100%", height: "150px" },
              }}
              redirect={false}
            /> */}
            <img src={event.headerImage} alt={event.name.capitalize()} />
            <div className="ticket-header-content">
              <h3>{event.name.capitalize()}</h3>
            </div>
          </div>
          <div className="ticket-content">
            <Row className="ticket-row fixed-content">
              <Col lg="12">
                <small className="ticket-label">Cortesía</small>
                <br />
                <div className="ticket-info">{courtesy.courtesyTicketTypeName.capitalize()}</div>
              </Col>
            </Row>
            <Row className="ticket-row">
              <Col lg="8">
                <small className="ticket-label">Fecha y Hora</small>
                <br />
                <FormatDate {...event} />
              </Col>
              <Col lg="4">
                <small className="ticket-label">Precio</small>
                <br />
                <div className="ticket-info">Cortesía</div>
              </Col>
            </Row>
            <Row className="ticket-row">
              <Col lg="12">
                <small className="ticket-label">Lugar</small>
                <br />
                <div className="ticket-address">{event.location.capitalize()}</div>
              </Col>
            </Row>

            <div className="ticket-qr-wrapper">
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${ticket.id}&size=300x300`}
                alt="qr ticket"
                className="ticket-qr"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  });
};
